var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Color", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can change the color of the component with the property "),
        _c("code", [_vm._v("color")]),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { icon: "warning", active: "true", color: "warning" },
        },
        [
          _c("span", [
            _vm._v("Only "),
            _c("strong", [_vm._v("RGB")]),
            _vm._v(" and "),
            _c("strong", [_vm._v("HEX")]),
            _vm._v(" colors are supported."),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "mt-5 demo-alignment" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.colorx,
                expression: "colorx",
              },
            ],
            attrs: { type: "color" },
            domProps: { value: _vm.colorx },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.colorx = $event.target.value
              },
            },
          }),
          _c(
            "vs-dropdown",
            { attrs: { color: _vm.colorx } },
            [
              _c(
                "a",
                { staticClass: "flex items-center", attrs: { href: "#" } },
                [
                  _vm._v(
                    "\n                    Dropdown hover\n                    "
                  ),
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v("expand_more"),
                  ]),
                ]
              ),
              _c(
                "vs-dropdown-menu",
                [
                  _c("vs-dropdown-item", [
                    _vm._v(
                      "\n                        option 1\n                    "
                    ),
                  ]),
                  _c("vs-dropdown-item", [
                    _vm._v(
                      "\n                        option 2\n                    "
                    ),
                  ]),
                  _c("vs-dropdown-item", { attrs: { divider: "" } }, [
                    _vm._v(
                      "\n                        option 3\n                    "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n\n    <div class="demo-alignment">\n\n      <input type="color" v-model="colorx">\n\n      <vs-dropdown :color="colorx">\n        <a class="flex items-center" href="#">\n          Dropdown hover\n          <i class="material-icons">expand_more</i>\n        </a>\n\n        <vs-dropdown-menu>\n\n          <vs-dropdown-item>\n            option 1\n          </vs-dropdown-item>\n\n          <vs-dropdown-item>\n            option 2\n          </vs-dropdown-item>\n\n          <vs-dropdown-item divider>\n            option 3\n          </vs-dropdown-item>\n        </vs-dropdown-menu>\n      </vs-dropdown>\n    </div>\n</template>\n\n<script>\nexport default {\n  data: () => ({\n    colorx: \'#c80948\'\n  }),\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }