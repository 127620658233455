var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      attrs: {
        title: "Group Collapse",
        "code-toggler": "",
        "no-shadow": "",
        "card-border": "",
      },
    },
    [
      _c(
        "vs-dropdown",
        [
          _c(
            "a",
            { staticClass: "flex items-center", attrs: { "href.prevent": "" } },
            [
              _vm._v(
                "\n                Dropdown Group Collapse\n                "
              ),
              _c("i", { staticClass: "material-icons" }, [
                _vm._v("expand_more"),
              ]),
            ]
          ),
          _c(
            "vs-dropdown-menu",
            [
              _c("vs-dropdown-item", { attrs: { to: "/components/" } }, [
                _vm._v(" Option 1 "),
              ]),
              _c("vs-dropdown-item", [_vm._v(" Option 2 ")]),
              _c(
                "vs-dropdown-group",
                {
                  attrs: {
                    "vs-collapse": "",
                    "vs-label": "Group Collapse",
                    "vs-icon": "add",
                  },
                },
                [
                  _c("vs-dropdown-item", [_vm._v(" Option Collapse 1 ")]),
                  _c("vs-dropdown-item", [_vm._v(" Option Collapse 2 ")]),
                  _c(
                    "vs-dropdown-group",
                    [
                      _c("vs-dropdown-item", [_vm._v(" Sub Options 1 ")]),
                      _c("vs-dropdown-item", [_vm._v(" Sub Options 2 ")]),
                      _c(
                        "vs-dropdown-group",
                        { attrs: { "vs-collapse": "" } },
                        [
                          _c(
                            "vs-dropdown-item",
                            { attrs: { "vs-label": "Sub Collapse" } },
                            [_vm._v(" Sub Collapse 1 ")]
                          ),
                          _c("vs-dropdown-item", [_vm._v(" Sub Collapse 2 ")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("vs-dropdown-item", { attrs: { divider: "" } }, [
                _vm._v(" Option 3 "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n\n  <div class="demo-alignment">\n\n    <vs-dropdown>\n\n      <a class="flex items-center" href.prevent>\n          Dropdown Group Collapse\n          <i class="material-icons">expand_more</i>\n      </a>\n\n      <vs-dropdown-menu>\n\n        <vs-dropdown-item to="/components/"> Option 1 </vs-dropdown-item>\n        <vs-dropdown-item> Option 2 </vs-dropdown-item>\n\n        <vs-dropdown-group vs-collapse vs-label="Group Collapse" vs-icon="add">\n\n          <vs-dropdown-item> Option Collapse 1 </vs-dropdown-item>\n          <vs-dropdown-item> Option Collapse 2 </vs-dropdown-item>\n\n          <vs-dropdown-group>\n\n            <vs-dropdown-item> Sub Options 1 </vs-dropdown-item>\n            <vs-dropdown-item> Sub Options 2 </vs-dropdown-item>\n\n            <vs-dropdown-group vs-collapse>\n\n              <vs-dropdown-item vs-label="Sub Collapse"> Sub Collapse 1 </vs-dropdown-item>\n              <vs-dropdown-item> Sub Collapse 2 </vs-dropdown-item>\n\n            </vs-dropdown-group>\n          </vs-dropdown-group>\n        </vs-dropdown-group>\n\n        <vs-dropdown-item divider> Option 3 </vs-dropdown-item>\n      </vs-dropdown-menu>\n    </vs-dropdown>\n  </div>\n</template>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }