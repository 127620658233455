var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Custom Content", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "Sometimes when we need something more personalized and not necessarily a menu for it you can add any content and have the dropdown functionality with the property "
        ),
        _c("code", [_vm._v("vs-custom-content")]),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("p", [
            _vm._v(
              "For better functionality in the user's aspect when doing some interaction with the custom dropdown you can add that it is only activated and deactivated by a click event with the property "
            ),
            _c("code", [_vm._v("vs-trigger-click")]),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "mt-5 demo-alignment" },
        [
          _c(
            "vs-dropdown",
            { attrs: { "vs-custom-content": "", "vs-trigger-click": "" } },
            [
              _c(
                "a",
                {
                  staticClass: "flex items-center",
                  attrs: { "href.prevent": "" },
                },
                [
                  _c("span", [_vm._v("Click me open login")]),
                  _c("feather-icon", {
                    attrs: {
                      icon: "ChevronDownIcon",
                      svgClasses: "h-4 w-4 ml-1",
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-dropdown-menu",
                { staticClass: "dropdown-login" },
                [
                  _c("h3", { staticClass: "mb-0" }, [_vm._v("Login")]),
                  _c("vs-input", {
                    attrs: { type: "email", "label-placeholder": "Email" },
                    model: {
                      value: _vm.value1,
                      callback: function ($$v) {
                        _vm.value1 = $$v
                      },
                      expression: "value1",
                    },
                  }),
                  _c("vs-input", {
                    attrs: {
                      type: "password",
                      "label-placeholder": "Password",
                    },
                    model: {
                      value: _vm.value2,
                      callback: function ($$v) {
                        _vm.value2 = $$v
                      },
                      expression: "value2",
                    },
                  }),
                  _c(
                    "vs-button",
                    {
                      staticClass: "mt-4 w-full",
                      attrs: {
                        width: "100%",
                        color: "success",
                        type: "gradient",
                      },
                    },
                    [_vm._v("Login")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n\n  <vs-dropdown vs-custom-content vs-trigger-click>\n    <a class="flex items-center" href.prevent>\n      <span>Click me open login</span>\n      <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4 ml-1" />\n    </a>\n\n    <vs-dropdown-menu class="dropdown-login">\n      <h3 class="mb-0">Login</h3>\n      <vs-input type="email" label-placeholder="Email" v-model="value1" />\n      <vs-input type="password" label-placeholder="Password" v-model="value2" />\n      <vs-button width="100%" color="success" type="gradient" class="mt-4 w-full">Login</vs-button>\n    </vs-dropdown-menu>\n\n  </vs-dropdown>\n</template>\n\n<script>\nexport default {\n  data: () => ({\n    value1: \'\',\n    value2: \'\',\n  }),\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }