var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Group Option" } },
    [
      _c("p", [
        _vm._v(
          "If you need to group the options you can use the subcomponent "
        ),
        _c("code", [_vm._v("vs-dropdown-group")]),
        _vm._v(" which as a required parameter is "),
        _c("code", [_vm._v("vs-label")]),
        _vm._v(" to define the group title"),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "my-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("p", [
            _vm._v(
              "The group can be hidden from the user and only open when it is sitting on the property with the "
            ),
            _c("code", [_vm._v("vs-collapse")]),
            _vm._v(" property"),
          ]),
          _c("p", [
            _vm._v("You can modify the icon with a property "),
            _c("code", [_vm._v("vs-icon")]),
          ]),
        ]
      ),
      _c("dropdown-option-group"),
      _c("dropdown-group-collapse"),
      _c("template", { slot: "codeContainer" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }