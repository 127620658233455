var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      attrs: {
        title: "Option Grouping",
        "code-toggler": "",
        "no-shadow": "",
        "card-border": "",
      },
    },
    [
      _c(
        "vs-dropdown",
        [
          _c(
            "a",
            { staticClass: "flex items-center", attrs: { "href.prevent": "" } },
            [
              _vm._v(
                "\n                Dropdown Option Group\n                "
              ),
              _c("i", { staticClass: "material-icons" }, [
                _vm._v(" expand_more "),
              ]),
            ]
          ),
          _c(
            "vs-dropdown-menu",
            [
              _c("vs-dropdown-item", [_vm._v(" Option 1 ")]),
              _c("vs-dropdown-item", [_vm._v(" Option 2 ")]),
              _c(
                "vs-dropdown-group",
                [
                  _c("vs-dropdown-item", [_vm._v(" Option 1 ")]),
                  _c("vs-dropdown-item", [_vm._v(" Option 2 ")]),
                  _c(
                    "vs-dropdown-group",
                    [
                      _c("vs-dropdown-item", [_vm._v(" sub Options 1 ")]),
                      _c("vs-dropdown-item", [_vm._v(" sub Options 2 ")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("vs-dropdown-item", { attrs: { divider: "" } }, [
                _vm._v(" Option 3 "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n\n  <div class="demo-alignment">\n\n    <vs-dropdown>\n\n      <a class="flex items-center" href.prevent>\n          Dropdown Option Group\n          <i class="material-icons"> expand_more </i>\n      </a>\n\n      <vs-dropdown-menu>\n\n        <vs-dropdown-item> Option 1 </vs-dropdown-item>\n        <vs-dropdown-item> Option 2 </vs-dropdown-item>\n\n        <vs-dropdown-group>\n\n          <vs-dropdown-item> Option 1 </vs-dropdown-item>\n          <vs-dropdown-item> Option 2 </vs-dropdown-item>\n\n          <vs-dropdown-group>\n\n            <vs-dropdown-item> sub Options 1 </vs-dropdown-item>\n            <vs-dropdown-item> sub Options 2 </vs-dropdown-item>\n\n          </vs-dropdown-group>\n        </vs-dropdown-group>\n\n        <vs-dropdown-item divider> Option 3 </vs-dropdown-item>\n      </vs-dropdown-menu>\n    </vs-dropdown>\n  </div>\n</template>\n\n<template>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }