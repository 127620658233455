var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Default", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "To add the Dropdown we have three types of components the main "
        ),
        _c("code", [_vm._v("vs-dropdown")]),
        _vm._v(" that contains the element that makes the "),
        _c("code", [_vm._v("vs-dropdown-menu")]),
        _vm._v(" appear and to add each item within it we have the component "),
        _c("code", [_vm._v("vs-dropdown-item")]),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("p", [
            _vm._v("To add an internal link using "),
            _c("a", { attrs: { href: "https://router.vuejs.org/" } }, [
              _vm._v("vue-router"),
            ]),
            _vm._v(" you can do them simply by adding the property "),
            _c("a", { attrs: { href: "https://router.vuejs.org/api/#to" } }, [
              _vm._v("to"),
            ]),
            _vm._v(" as if it were a "),
            _c("a", { attrs: { href: "https://router.vuejs.org/" } }, [
              _vm._v("vue-router"),
            ]),
            _vm._v(" link"),
          ]),
          _c("p", [
            _vm._v(
              "In case you need an external link or normal html, simply do it with the href property"
            ),
          ]),
        ]
      ),
      _c(
        "prism",
        { staticClass: "rounded-lg", attrs: { language: "markup" } },
        [
          _vm._v(
            '\n  <!-- to: internal link -->\n  <vs-dropdown-item to="/myLink">\n    my Link name\n  </vs-dropdown-item>\n\n  <!-- href: external link-->\n  <vs-dropdown-item href="/myLink">\n    my Link name\n  </vs-dropdown-item>\n            '
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "mt-5 demo-alignment" },
        [
          _c(
            "vs-dropdown",
            [
              _c(
                "a",
                { staticClass: "flex items-center", attrs: { href: "#" } },
                [
                  _vm._v(
                    "\n                    Dropdown hover\n                    "
                  ),
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v(" expand_more "),
                  ]),
                ]
              ),
              _c(
                "vs-dropdown-menu",
                [
                  _c("vs-dropdown-item", [
                    _vm._v(
                      "\n                        Option 1\n                    "
                    ),
                  ]),
                  _c("vs-dropdown-item", [
                    _vm._v(
                      "\n                        Option 2\n                    "
                    ),
                  ]),
                  _c("vs-dropdown-item", { attrs: { divider: "" } }, [
                    _vm._v(
                      "\n                        Option 3\n                    "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vs-dropdown",
            [
              _c(
                "a",
                {
                  staticClass: "flex items-center",
                  attrs: { "href.prevent": "" },
                },
                [
                  _vm._v(
                    "\n                    Dropdown Option Disabled\n                    "
                  ),
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v("expand_more"),
                  ]),
                ]
              ),
              _c(
                "vs-dropdown-menu",
                [
                  _c("vs-dropdown-item", [
                    _vm._v(
                      "\n                        Option 1\n                    "
                    ),
                  ]),
                  _c("vs-dropdown-item", { attrs: { disabled: "" } }, [
                    _vm._v(
                      "\n                        Option 2\n                    "
                    ),
                  ]),
                  _c(
                    "vs-dropdown-item",
                    { attrs: { disabled: "", divider: "" } },
                    [
                      _vm._v(
                        "\n                        Option 3\n                    "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<div class="demo-alignment">\n\n  <vs-dropdown>\n\n    <a class="flex items-center" href="#">\n      Dropdown hover\n      <i class="material-icons"> expand_more </i>\n    </a>\n\n    <vs-dropdown-menu>\n\n      <vs-dropdown-item>\n        Option 1\n      </vs-dropdown-item>\n\n      <vs-dropdown-item>\n        Option 2\n      </vs-dropdown-item>\n\n      <vs-dropdown-item divider>\n        Option 3\n      </vs-dropdown-item>\n\n    </vs-dropdown-menu>\n  </vs-dropdown>\n\n  <vs-dropdown>\n\n    <a class="flex items-center" href.prevent>\n      Dropdown Option Disabled\n      <i class="material-icons">expand_more</i>\n    </a>\n\n    <vs-dropdown-menu>\n\n      <vs-dropdown-item>\n        Option 1\n      </vs-dropdown-item>\n\n      <vs-dropdown-item disabled>\n        Option 2\n      </vs-dropdown-item>\n\n      <vs-dropdown-item disabled divider>\n        Option 3\n      </vs-dropdown-item>\n    </vs-dropdown-menu>\n  </vs-dropdown>\n</div>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }